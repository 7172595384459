.adm-segmented {
  --segmented-background: var(--adm-color-fill-content);
  --segmented-item-color: var(--adm-color-text-secondary);
  --segmented-item-selected-background: var(--adm-color-background);
  --segmented-item-selected-color: var(--adm-color-text);
  --segmented-item-disabled-color: var(--adm-color-weak);
  --transition-time-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  display: inline-block;
  padding: 2px;
  color: var(--segmented-item-color);
  background-color: var(--segmented-background);
  border-radius: 2px;
  transition: all 0.3s var(--transition-time-function);
}
.adm-segmented-group {
  position: relative;
  display: flex;
  align-items: stretch;
  justify-items: flex-start;
  width: 100%;
}
.adm-segmented.adm-segmented-block {
  display: flex;
}
.adm-segmented.adm-segmented-block .adm-segmented-item {
  flex: 1;
  min-width: 0;
}
.adm-segmented-item {
  position: relative;
  text-align: center;
  cursor: pointer;
  transition: color 0.3s var(--transition-time-function);
}
.adm-segmented-item-selected {
  background-color: var(--segmented-item-selected-background);
  border-radius: 2px;
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05), 0 1px 4px -1px rgba(0, 0, 0, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
  color: var(--segmented-item-selected-color);
}
.adm-segmented-item-label {
  min-height: 10px;
  padding: 0 11px;
  line-height: 28px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.adm-segmented-item-icon + * {
  margin-left: 6px;
}
.adm-segmented-item-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}
.adm-segmented-item-disabled {
  color: var(--segmented-item-disabled-color);
  cursor: not-allowed;
}
.adm-segmented-thumb {
  background-color: var(--segmented-item-selected-background);
  border-radius: 2px;
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05), 0 1px 4px -1px rgba(0, 0, 0, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  padding: 4px 0;
}
.adm-segmented-thumb-motion-appear-active {
  transition: transform 0.3s var(--transition-time-function), width 0.3s var(--transition-time-function);
  will-change: transform, width;
}
/* ---- mixins part starts ---- */
.segmented-disabled-item {
  color: var(--segmented-item-disabled-color);
  cursor: not-allowed;
}
.segmented-item-selected {
  background-color: var(--segmented-item-selected-background);
  border-radius: 2px;
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05), 0 1px 4px -1px rgba(0, 0, 0, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
}
.segmented-text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}
/* ---- mixins part ends ---- */
